/*
 * Globals
 */

/* Links */
a,
a:focus,
a:hover {
  color: #fff;
}

/* Custom default button */
.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus {
  color: #333;
  text-shadow: none; /* Prevent inheritance from `body` */
  background-color: #fff;
  border: 0.05rem solid #fff;
}

/*
 * Base structure
 */

html,
body {
  height: 100%;
  background-color: #333 !important;
  width: 100vw;
}

body > #root {
  text-shadow: 0 0.05rem 0.1rem rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 5rem rgba(0, 0, 0, 0.5);
}


.jumbotron {
  padding: 2rem 1rem !important;
}

.cover-container {
  max-width: 45em;
}

/*
 * Footer
 */
footer {
  color: rgba(255, 255, 255, 0.5);
}

/* Background */
.bg {
  height: 100vh;
  background-position: center;
  background-repeat: repeat;
  max-width: 100% !important;
  display: inline-flex;
}
